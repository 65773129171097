<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('range')" v-if="loginType.name=='merchantLogin'">
          <el-select v-model="queryForm.isOrgCus" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('org')" :value="0"></el-option>
            <el-option :label="$t('personal')" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cardNumber')" v-if="loginType.name=='merchantLogin'">
          <el-input v-model="queryForm.ccNo" size="small" />
        </el-form-item>
        <el-form-item :label="$t('transactionCode')">
          <el-input v-model="queryForm.transactionCode" size="small" />
        </el-form-item>
        <el-form-item :label="$t('customerCode')" v-if="loginType.name=='merchantLogin'">
          <el-input v-model="queryForm.customerCode" size="small" />
        </el-form-item>
        <el-form-item :label="$t('whereaboutsFund')">
          <el-select v-model="queryForm.addSub" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('expenditure')" value="0"></el-option>
            <el-option :label="$t('income')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-if="loginType.name=='merchantLogin'" type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
          <el-button v-else type="primary" size="small" @click="cuGetList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column v-if="loginInfo.orgType==1||loginType.name=='customerLogin'" prop="orgName" :label="$t('operator')" width="300">
          <template slot-scope="scope">
            {{scope.row.orgName}}({{(scope.row.createName||'')+' '+(scope.row.createSurname||'')}})
          </template>
        </el-table-column>
        <el-table-column v-else prop="orgUserName+orgUserSurname" :label="$t('operator')" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{(scope.row.createName||'')+' '+(scope.row.createSurname||'')}}
          </template>
        </el-table-column>
        <el-table-column prop="addSub" :label="$t('whereaboutsFund')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.addSub=='+'" class="text-success">{{$t('income')}}</span>
            <span v-if="scope.row.addSub=='-'" class="text-warning">{{$t('expenditure')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="beforeAmount" :label="$t('beforeAmount')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.beforeAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="afterAmount" :label="$t('afterAmount')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.afterAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="businessName" :label="$t('businessName')" width="160">
          <template slot-scope="scope">{{$t(scope.row.businessName)}}</template>
        </el-table-column>
        <el-table-column prop="ccNo" :label="$t('cardNumber')" width="160"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" width="240">
          <template slot-scope="scope">
            <div class="overstep-hidden">
              <translation :text="scope.row.remark" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="transactionCode" :label="$t('transactionCode')" width="200"></el-table-column>
        <!-- <el-table-column prop="type" :label="$t('type')" width="160">
          <template slot-scope="scope">{{typeMatching(scope.row.type)}}</template>
        </el-table-column> -->
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import translation from "../../components/specialTranslation.vue"

import card from '@/api/card'
import customerBusiness from '@/api/customerBusiness'
import storage from '@/assets/uilt/storage'

export default {
  name: "cardRecord",
  components: { translation },
  data () {
    return {
      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      /**查询条件 */
      queryForm: {},
    }
  },
  computed: {
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    loginType () { return storage.local.get("loginName") }
  },
  methods: {
    getList () {
      let _this = this;
      _this.tableData.records = []
      _this.isLoading = true;
      card.listCardFlow({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          if (res.code != 200) _this.$message.warning(_this.$t(res.msg || '500:An error occurred inside the program'))
          _this.tableData = res.data || {};
        }
      })
    },
    cuGetList () {
      let _this = this;
      _this.tableData.records = []
      _this.isLoading = true;
      customerBusiness.listCardFlow({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          if (res.code != 200) _this.$message.warning(_this.$t(res.msg || '500:An error occurred inside the program'))
          _this.tableData = res.data || {};
        }
      })
    },

    setPageSzie (val) {
      this.$set(this, "pageSize", val);
      this.$set(this, "currentPage", 1);
      if (this.loginType.name == 'merchantLogin')
        this.getList();
      else this.cuGetList()
    },
    setCurrentChange (val) {
      this.$set(this, "currentPage", val);
      if (this.loginType.name == 'merchantLogin')
        this.getList();
      else this.cuGetList()
    },
    typeMatching (val) {
      switch (val) {
        case 1: return this.$t('cashNotAccount');
        case 2: return this.$t('accountRemittanceNotAccount');
        case 3: return this.$t('transferAccount');
        case 4: return this.$t('deposit');
        case 5: return this.$t('accountDrawMoney');
        default:
          break;
      }
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    if (this.loginType.name == 'merchantLogin') this.getList();
    else this.cuGetList();
  },
}
</script>

<style>
</style>