<template>
  <span>{{formatText}}</span>
</template>

<script>
export default {
  props: { text: String },
  data () {
    return {
      formatText: ""
    }
  },
  mounted () {
    console.log(this.text)
    const arr = ["service charge", "transaction tax", "organization remuneration", "withdrawal organization remuneration", "platform remuneration"];
    try {
      let arr2 = this.text.split(":");
      let arr3 = this.text.split(",");

      if (arr3 && arr3.length == 2) {
        let arr4 = arr3[0].split(":");
        this.formatText += this.$t(arr4[0]) + ":" + arr4[1] + ","
        let arr5 = arr3[1].split(":");
        this.formatText += this.$t(arr5[0]) + ":" + arr5[1]
      }
      else if (this.text && arr.indexOf(arr2[0]) != -1)
        this.formatText = this.$t(arr2[0]) + ":" + arr2[1]
      else
        this.formatText = this.$t(this.text || "--");
    } catch {
      this.formatText = this.$t(this.text || "--");
    }
  }
}
</script>

<style lang="less" scoped>
</style>